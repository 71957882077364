@tailwind base;
@tailwind components;
@tailwind utilities;

.grayscale {
  backdrop-filter: grayscale(100%) blur(4px);
  transition: backdrop-filter 2s ease-in;
}

.grayscale.transition {
  backdrop-filter: grayscale(0%) blur(4px);
}

.grayscale-no-blur {
  backdrop-filter: grayscale(100%);
  transition: backdrop-filter 2s ease-in;
}

.grayscale-no-blur.transition {
  backdrop-filter: grayscale(0%);
}

.grayscale-fg {
  filter: grayscale(100%);
  transition: filter 2s ease-in;
}

.grayscale-fg.transition {
  filter: grayscale(0%);
}

.question .arrow.open {
  display: inline-block;
  transform: rotate(-180deg);
}

.control-arrow {
  background-color: rgba(0, 0, 0, 0.2) !important;
}